import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import './about.css'

const About = () => (
  <Layout pageTitle="About">
    <SEO title="About" />
    <h1>About me</h1>
    <br></br>
    <p className="about-paragraph">
      I have been programming since 2016. I am a mostly self taught developer, but I have completed some <Link to="/certs">courses</Link>. 
    </p>
    <p className="about-paragraph">  
      My main focus is full stack web development, 
      but I have also built browser extensions, games, Excel and Android applications.
    </p>
    <p className="about-paragraph">  
      I have completed and published over a dozen of <Link to="/">projects</Link>.
    </p>
    <p className="about-paragraph">  
      This is my <a href="https://github.com/micpob" target="_blank" rel="noopener noreferrer">Github</a>.
    </p>
    <p className="about-paragraph">  
      Feel free to <Link to="../contact">contact me</Link> for anything. 
    </p>
  </Layout>
)

export default About